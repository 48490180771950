<template>
  <div class="service">
    service
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss">
  .service {
    
  }
</style>
